import { gameFinished } from "../helpers/gameHelpers";
import { useGame } from "../hooks/useGame";
import { newGame, saveGame } from "../reducers/scoreblokReducer";
import { GameWritable } from '../types/Game';
import { useCallback, useMemo, useState } from 'react';
import ScoreModal from './ScoreModal';
import { useDispatch } from 'react-redux';

type Props = {
    game: GameWritable,
};

const scoreOptions = new Map<keyof GameWritable, number[]>([
    ['ones', [0,1,2,3,4,5]],
    ['twos', [0,2,4,6,8,10]],
    ['threes', [0,3,6,9,12,15]],
    ['fours', [0,4,8,12,16,20]],
    ['fives', [0,5,10,15,20,25]],
    ['sixes', [0,6,12,18,24,30]],
    ['fullHouse', [0,25]],
    ['smallStreet', [0,30]],
    ['largeStreet', [0,40]],
    ['yahtzee', [0,50,150,250,350,450,550,650]],
]);

const GameScoreRow = ({ score, onClick }: { score: number | null, onClick?: () => void }) => (
  <div className={`scoreblok-row ${score === 0 ? 'missed' : ''}`} onClick={onClick}>{score || ''}</div>
);

const GameScore = ({game}: Props) => {
    const dispatch = useDispatch();
    const [selectedType, setSelectedType] = useState<keyof GameWritable | null>(null);
    const options = useMemo(() => selectedType === null ? [] : scoreOptions.get(selectedType), [selectedType]);
    const { finishedTopHalf, finishedBottomHalf, subtotalAbove, bonus, totalAbove, totalBelow, total } = useGame(game);

    const setScore = useCallback((value: number) => {
        if (!selectedType) {
            return;
        }

        const g = {...game};
        g[selectedType] = value;
        dispatch(saveGame(g));
        if (gameFinished(g)) {
            dispatch(newGame());
        }
    }, [dispatch, game, selectedType]);

    return (
      <>
          <div className="scoreblok-column">
              <div className="scoreblok-row header">#{ game.id }</div>
              <GameScoreRow score={game['ones']} onClick={() => setSelectedType('ones')} />
              <GameScoreRow score={game['twos']} onClick={() => setSelectedType('twos')} />
              <GameScoreRow score={game['threes']} onClick={() => setSelectedType('threes')} />
              <GameScoreRow score={game['fours']} onClick={() => setSelectedType('fours')} />
              <GameScoreRow score={game['fives']} onClick={() => setSelectedType('fives')} />
              <GameScoreRow score={game['sixes']} onClick={() => setSelectedType('sixes')} />
              <GameScoreRow score={finishedTopHalf ? subtotalAbove : null} />
              <GameScoreRow score={finishedTopHalf ? bonus : null} />
              <GameScoreRow score={finishedTopHalf ? totalAbove : null} />
              <GameScoreRow score={null} />
              <GameScoreRow score={game['threeOfAKind']} onClick={() => setSelectedType('threeOfAKind')} />
              <GameScoreRow score={game['fourOfAKind']} onClick={() => setSelectedType('fourOfAKind')} />
              <GameScoreRow score={game['fullHouse']} onClick={() => setSelectedType('fullHouse')} />
              <GameScoreRow score={game['smallStreet']} onClick={() => setSelectedType('smallStreet')} />
              <GameScoreRow score={game['largeStreet']} onClick={() => setSelectedType('largeStreet')} />
              <GameScoreRow score={game['yahtzee']} onClick={() => setSelectedType('yahtzee')} />
              <GameScoreRow score={game['chance']} onClick={() => setSelectedType('chance')} />
              <GameScoreRow score={finishedBottomHalf ? totalBelow : null} />
              <GameScoreRow score={finishedBottomHalf ? totalAbove : null} />
              <GameScoreRow score={finishedBottomHalf ? total : null} />
          </div>
          {selectedType !== null && (
              <ScoreModal
                type={selectedType}
                onValueSelect={(value) => {
                    setScore(value);
                    setSelectedType(null);
                }}
                options={options || undefined}
                visible
                onClose={() => setSelectedType(null)}
              />
          )}
      </>
    )
}
export default GameScore;
