import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { gameFinished, gameTotal } from "../helpers/gameHelpers";
import { GameWritable } from "../types/Game";
import Modal from './Modal';
import { RootState } from '../app/store';
import './StatisticsModal.scss';

type Props = {
    visible: boolean;
    onClose?: () => void;
}

const StatisticsModal = ({ visible, onClose }: Props) => {
    const games = useSelector<RootState, GameWritable[]>((state) => state.scoreblok.games);
    const totalScore = useMemo(() => games.filter((game) => gameFinished(game)).map((game) => gameTotal(game) || 0).reduce((prevVal, val) => prevVal + val, 0), [games]);
    const highestScore = useMemo(() => Math.max(...games.filter((game) => gameFinished(game)).map((game) => gameTotal(game) || 0)), [games]);
    const lowestScore = useMemo(() => Math.min(...games.filter((game) => gameFinished(game)).map((game) => gameTotal(game) || 0)), [games]);

    return (
        <Modal visible={visible} title="Statistieken" onClose={onClose}>
            <table className="statisticsTable">
                <tbody>
                <tr>
                    <th>Totaal score</th>
                    <td>{totalScore}</td>
                </tr>
                <tr>
                    <th>Hoogste score</th>
                    <td>{highestScore}</td>
                </tr>
                <tr>
                    <th>Laagste score</th>
                    <td>{lowestScore}</td>
                </tr>
                </tbody>
            </table>
      </Modal>
    );
}

export default StatisticsModal;
