import { useMemo } from "react";
import {
  gameBonus,
  gameFinishedBottomHalf,
  gameFinishedTopHalf,
  gameSubtotalAbove, gameTotal,
  gameTotalAbove, gameTotalBelow
} from "../helpers/gameHelpers";
import { GameWritable } from "../types/Game";

export const useGame = (game: GameWritable) => {
  const finishedTopHalf = useMemo(() => gameFinishedTopHalf(game), [game]);
  const finishedBottomHalf = useMemo(() => gameFinishedBottomHalf(game), [game]);

  const subtotalAbove = useMemo(() => gameSubtotalAbove(game), [game]);
  const bonus = useMemo(() => gameBonus(game), [game]);
  const totalAbove = useMemo(() => gameTotalAbove(game), [game]);
  const totalBelow = useMemo(() => gameTotalBelow(game), [game]);
  const total = useMemo(() => gameTotal(game), [game]);

  return { finishedTopHalf, finishedBottomHalf, subtotalAbove, bonus, totalAbove, totalBelow, total };
}
