import { configureStore } from "@reduxjs/toolkit";
import scoreblokReducer from '../reducers/scoreblokReducer';

export const store = configureStore({
  reducer: {
    scoreblok: scoreblokReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
