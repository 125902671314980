import { FormEvent, useCallback, useMemo, useState } from 'react';
import './ScoreModal.scss';
import { GameWritable } from "../types/Game";
import Modal from './Modal';

const typeTranslations = new Map<keyof GameWritable, string>([
    ['ones', 'enen'],
    ['twos', 'tweeën'],
    ['threes', 'drieën'],
    ['fours', 'vieren'],
    ['fives', 'vijven'],
    ['sixes', 'zessen'],
    ['threeOfAKind', 'drie dezelfde'],
    ['fourOfAKind', 'vier dezelfde'],
    ['fullHouse', 'fullhouse'],
    ['smallStreet', 'kleine straat'],
    ['largeStreet', 'grote straat'],
    ['yahtzee', 'yahtzee'],
    ['chance', 'vrije keus']
])

type Props = {
    type: keyof GameWritable;
    options?: number[];
    visible: boolean;
    currentValue?: number;

    onValueSelect: (value: number) => void;
    onClose: () => void;
};

const ScoreModal = ({ type, options, visible, currentValue, onValueSelect, onClose }: Props) => {
    const modalTitle = useMemo(() => {
        const translation = typeTranslations.get(type) || type;
        return options ? `Selecteer een waarde voor ${translation}` : `Vul een waarde in voor ${translation}`;
    }, [options, type]);

    const [scoreInput, setScoreInput] = useState(currentValue || 0);

    const handleFormSubmit = useCallback((event: FormEvent) => {
        event.preventDefault();
        onValueSelect(scoreInput);
        setScoreInput(0);
    }, [onValueSelect, scoreInput]);

    return (
        <Modal title={modalTitle} visible={visible} onClose={onClose}>
            {options ? (
                <div className="options">
                    {options?.map((option) => (
                        <div className="option button" onClick={() => onValueSelect(option)} key={option}>{option}</div>
                    ))}
                </div>
            ) : (
                <form onSubmit={handleFormSubmit}>
                    <input
                        className="scoreInput"
                        type="number"
                        min="0"
                        max="30"
                        step="1"
                        autoFocus
                        inputMode="decimal"
                        onChange={event => setScoreInput(parseInt(event.target.value))}
                        value={Number.isNaN(scoreInput) ? '' : scoreInput.toString()}
                    />
                    <button type="submit" className="scoreSubmit">Opslaan</button>
                </form>
            )}
        </Modal>
    );
}

export default ScoreModal;
