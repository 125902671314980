import React from 'react';
import Scoreblok from './components/Scoreblok';

function App() {
  return (
    <div className="App">
      <Scoreblok />
    </div>
  );
}

export default App;
