import { useEffect, useState } from 'react';
import { loadGames, newGame } from "../reducers/scoreblokReducer";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../app/store';
import "./Scoreblok.scss";
import { GameWritable } from "../types/Game";
import GameScore from './GameScore';
import MenuModal from './MenuModal';

const Scoreblok = () => {
    const dispatch = useDispatch();
    const games = useSelector<RootState, GameWritable[]>((state) => state.scoreblok.games);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        dispatch(loadGames());
    }, [dispatch]);

    return (
      <>
          <div className="menu">
              <button type="button" className="menuButton" onClick={() => setShowMenu(true)}>Menu</button>
              <button type="button" onClick={() => dispatch(newGame())}>+</button>
          </div>

          <div className="scoreblok">
              <div className="scoreblok-column title">
                  <div className="scoreblok-row"/>
                  <div className="scoreblok-row">Enen</div>
                  <div className="scoreblok-row">Tweeën</div>
                  <div className="scoreblok-row">Drieën</div>
                  <div className="scoreblok-row">Vieren</div>
                  <div className="scoreblok-row">Vijven</div>
                  <div className="scoreblok-row">Zessen</div>
                  <div className="scoreblok-row">Subtotaal</div>
                  <div className="scoreblok-row">Bonus</div>
                  <div className="scoreblok-row">Totaal boven</div>
                  <div className="scoreblok-row"/>
                  <div className="scoreblok-row">Drie dezelfde</div>
                  <div className="scoreblok-row">Vier dezelfde</div>
                  <div className="scoreblok-row">Full-house</div>
                  <div className="scoreblok-row">Kleine straat</div>
                  <div className="scoreblok-row">Grote straat</div>
                  <div className="scoreblok-row">Yathzee</div>
                  <div className="scoreblok-row">Vrije keus</div>
                  <div className="scoreblok-row">Totaal onder</div>
                  <div className="scoreblok-row">Totaal boven</div>
                  <div className="scoreblok-row">Totaal</div>
              </div>

              {games.map(game => <GameScore game={game} key={game.id}/>)}
          </div>

          <MenuModal visible={showMenu} onClose={() => setShowMenu(false)} />
      </>
    )
}

export default Scoreblok;
