import React, { useCallback, useState } from 'react';
import { newGame, reset } from "../reducers/scoreblokReducer";
import Modal from './Modal';
import { useDispatch } from 'react-redux';
import './MenuModal.scss';
import StatisticsModal from './StatisticsModal';

type Props = {
    visible: boolean;
    onClose?: () => void;
}

const MenuModal = ({ visible, onClose }: Props) => {
    const [showStatisticsModal, setShowStatisticsModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    const clickWrapper = useCallback((handler: () => void) => {
        return () => {
            onClose?.();
            handler();
        };
    }, [onClose]);

    const addNewGame = clickWrapper(() => {
        dispatch(newGame());
    });

    const resetScoreblok = clickWrapper(() => {
        dispatch(reset());
    });

    return (
        <>
            <Modal visible={visible} title="Menu" onClose={onClose}>
                <div className="menuModal">
                    <button type="button" onClick={addNewGame}>Nieuw spel</button>
                    <button type="button" onClick={clickWrapper(() => setShowStatisticsModal(true))}>Statistieken</button>
                    <button type="button" onClick={resetScoreblok}>Reset scoreblok</button>
                </div>
            </Modal>
            <StatisticsModal visible={showStatisticsModal} onClose={() => setShowStatisticsModal(false)} />
        </>
    );
};

export default MenuModal;
