import { GameWritable } from '../types/Game';

export function createGame(id: number): GameWritable {
    return {
        id,
        ones: null,
        twos: null,
        threes: null,
        fours: null,
        fives: null,
        sixes: null,
        threeOfAKind: null,
        fourOfAKind: null,
        fullHouse: null,
        smallStreet: null,
        largeStreet: null,
        yahtzee: null,
        chance: null,
    };
}
