import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createGame } from '../factories/gameFactory';
import { GameWritable } from "../types/Game";

const LOCALSTORAGE_GAMES_KEY = 'games';

export interface ScoreblokState {
    games: GameWritable[];
}

const initialState: ScoreblokState = {
    games: [createGame(1)],
};

const scoreblokSlice = createSlice({
    name: 'scoreblok',
    initialState,
    reducers: {
        saveGame(state, action: PayloadAction<GameWritable>) {
            state.games = state.games.map((g) => g.id === action.payload.id ? action.payload : g);
            localStorage.setItem(LOCALSTORAGE_GAMES_KEY, JSON.stringify(state.games));
        },
        newGame(state) {
            state.games.push(createGame(Math.max(...state.games.map(g => g.id)) + 1));
            localStorage.setItem(LOCALSTORAGE_GAMES_KEY, JSON.stringify(state.games));
        },
        reset(state) {
            state.games = [createGame(1)];
            localStorage.setItem(LOCALSTORAGE_GAMES_KEY, JSON.stringify(state.games));
        },
        loadGames(state) {
            const gamesString = localStorage.getItem(LOCALSTORAGE_GAMES_KEY);
            if (gamesString === null) {
                state.games = [createGame(1)];
                return;
            }

            const games = JSON.parse(gamesString);
            if (!Array.isArray(games)) {
                state.games = [createGame(1)];
                return;
            }

            state.games = games;
        },
    },
});

export const { saveGame, newGame, reset, loadGames } = scoreblokSlice.actions;
export default scoreblokSlice.reducer;
