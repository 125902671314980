import React, { ReactNode } from 'react';
import './Modal.scss';

type Props = {
    children: ReactNode;
    title?: string;
    onClose?: () => void;
    visible: boolean;
}

const Modal = ({ children, onClose, title, visible }: Props) => {
    if (!visible) {
        return <></>;
    }

    return (
        <div className="modal">
            <div className="modal-background" onClick={onClose}/>
            <div className="modal-container">
                {title &&
                <div className="modal-title">{title}</div>
                }
                {children}
            </div>
        </div>
    );
};

export default Modal;
