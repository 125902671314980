import { GameWritable } from "../types/Game";

export function gameFinishedTopHalf(game: GameWritable) {
  return (['ones', 'twos', 'threes', 'fours', 'fives', 'sixes'] as (keyof GameWritable)[]).every((kind) => game[kind] !== null);
}

export function gameFinishedBottomHalf(game: GameWritable) {
  return (['threeOfAKind', 'fourOfAKind', 'fullHouse', 'smallStreet', 'largeStreet', 'yahtzee', 'chance'] as (keyof GameWritable)[]).every((kind) => game[kind] !== null);
}

export function gameFinished(game: GameWritable) {
  return gameFinishedTopHalf(game) && gameFinishedBottomHalf(game);
}

export function gameSubtotalAbove(game: GameWritable) {
  const total = (game['ones'] || 0) + (game['twos'] || 0) + (game['threes'] || 0) + (game['fours'] || 0) + (game['fives'] || 0) + (game['sixes'] || 0);
  return total > 0 ? total : null;
}

export function gameBonus(game: GameWritable) {
  const total = (gameSubtotalAbove(game) || 0) >= 63 ? 35 : 0;
  return total > 0 ? total : null;
}

export function gameTotalAbove(game: GameWritable) {
  const total = (gameSubtotalAbove(game) || 0) + (gameBonus(game) || 0);
  return total > 0 ? total : null;
}

export function gameTotalBelow(game: GameWritable) {
  const total = (game['threeOfAKind'] || 0) + (game['fourOfAKind'] || 0) + (game['fullHouse'] || 0) + (game['smallStreet'] || 0) + (game['largeStreet'] || 0) + (game['yahtzee'] || 0) + (game['chance'] || 0);
  return total > 0 ? total: null;
}

export function gameTotal(game: GameWritable) {
  const total = (gameTotalAbove(game) || 0) + (gameTotalBelow(game) || 0);
  return total > 0 ? total : null;
}
